<template>
  <div class="d-flex justify-content-between">
    <div class="form-group text-center">
      <label v-if="idx == '0'"><strong>{{ $t("booking.no-nights") }}</strong></label>
      <input type="text" class="form-control text-center" placeholder="0" readonly :value="NoNight" />
    </div>
    <!-- <div class="form-group icon">
      <label v-if="idx == '0'">{{ $t("booking.room") }} & {{ $t("booking.guest") }}</label>
      <input type="text" class="form-control" placeholder="1 room, 2 adults" readonly v-model="roomType">
      <div class="icon-form"><i class="fas fa-users"></i></div>
    </div> -->
    <div class="form-group icon text-center">
      <label v-if="idx == '0'"><strong>{{ $t("booking.hosting-base") }}</strong></label>
      <input type="text" class="form-control text-center" placeholder="2 bed, breakfast" readonly v-model="roomBasis"/>
      <div class="icon-form"><i class="fas fa-bed"></i></div>
    </div>
    <div class="form-group text-center">
      <label v-if="idx == '0'"><strong>{{ $t("booking.room-type") }}</strong></label>
      <input type="text" class="form-control text-center" placeholder="0" readonly v-model="roomClass"/>
    </div>
    <div class="form-group icon text-center">
      <label v-if="idx == '0'"><strong>{{ $t("booking.from-date") }}</strong></label>
      <input type="text" class="form-control text-center" placeholder="01/01/2021" readonly v-model="arrival"/>
      <div class="icon-form"><i class="far fa-calendar-alt"></i></div>
    </div>
    <div class="form-group icon text-center">
      <label v-if="idx == '0'"><strong>{{ $t("booking.until") }}</strong></label>
      <input type="text" class="form-control text-center" placeholder="01/01/2021" readonly v-model="departure"/>
      <div class="icon-form"><i class="far fa-calendar-alt"></i></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    idx: {
      type: Number,
      default: 1,
    },
    room: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      NoNight: 0,
      roomClass: '',
      // roomType: '',
      roomBasis: '',
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
    }),
    departure() {
      const { checkOut } = this.room;
      if (!checkOut) return '00/00/0000';
      return checkOut.split('-').reverse().join('/');
    },
    arrival() {
      const { checkIn } = this.room;
      if (!checkIn) return '00/00/0000';
      return checkIn.split('-').reverse().join('/');
    },
  },
  watch: {
    lang: 'updateLabelWithLang',
  },
  created() {
    this.NoNight = this.room.NoNight;
    this.ct = -1;
    this.updateLabelWithLang();
  },
  methods: {
    updateLabelWithLang() {
      this.roomClass = this.room.roomClassName;
      // this.roomType = this.room.translations.Room_Type[this.room.roomType.value][this.lang] || this.room.roomType.value;
      this.roomBasis = this.$t(`basis-name.${this.room.basisCode.toLowerCase()}`);
    },
  },
};
</script>

<style scoped>
.price_details_body .form-group {
  position: relative;
  width: 100%;
  margin: 2px 5px;
}

.form-group label {
  font-size: 15px;
}

.price_details_body .form-group .form-control {
  height: 42px;
  direction: ltr;
}

.price_details_body .form-group.icon .form-control {
  padding-left: 40px;
}

.form-control {
  display: block;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* direction: "ltr"; */
}

.price_details_body .form-group .icon-form {
  content: "";
  position: absolute;
  bottom: 8px;
  left: 14px;
  color: #b0b0b0;
}

@media (max-width: 767.98px) {
  div.d-flex.justify-content-between {
    display: inline !important;
  }

  .form-group label {
    display: none;
  }

  div.d-flex.justify-content-between > .form-group {
    width: 50%;
    display: inline-flex !important;
    margin-right: 0px !important;
    margin-left: 0px !important;
    margin-bottom: 0px;
  }

  div.d-flex.justify-content-between > .form-group:last-child {
    margin-bottom: 10px;
  }
}

@media (min-width: 768px) {
  .form-group label {
    font-size: 11px;
  }
}

@media (min-width: 992px) {
  .form-group label {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {
  .form-group label {
    font-size: 15px;
  }
}
</style>
